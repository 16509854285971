import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';

import Divider from 'components/Divider';
import Layout from 'components/Layout';
import {getTimeString} from 'components/Timestamp';
import BrandContext from 'contexts/BrandContext';

import useStyles from './styles';

interface SpeechInputProps {
  defaultValue?: string;
  onChange?: (value: string) => void;
}

export const SpeechInput: FC<SpeechInputProps> = ({
  defaultValue = '',
  onChange,
}) => {
  const {card, speechInput} = useContext(BrandContext);
  const classes = useStyles({...card, ...speechInput});
  const [value, setValue] = React.useState(defaultValue);
  const [active, setActive] = useState(false);
  useEffect(() => onChange?.(value), [value]);
  const speechRecognition = useMemo(() => {
    const SpeechRecognition =
      (window as any).SpeechRecognition ||
      (window as any).webkitSpeechRecognition;

    const recognition = new SpeechRecognition();
    recognition.lang = 'en-EN';
    recognition.interimResults = true;

    recognition.addEventListener('result', (e) => {
      const text = Array.from(e.results)
        .map((result: any) => result[0])
        .map((result) => result.transcript)
        .join('');

      setValue(value + (value ? '. ' : '') + text);
    });

    return recognition;
  }, [value]);

  const startRecord = useCallback(() => {
    if (active) {
      return;
    }
    speechRecognition.start();
    setActive(true);
  }, [active, speechRecognition, setActive, setValue]);

  const stopRecord = useCallback(() => {
    speechRecognition.stop();
    setActive(false);
  }, [speechRecognition, setActive]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const insertTimestamp = () => {
    const time = getTimeString({time: true});
    const line = value.split('\n')[0];
    if (!moment(line, 'LT', true).isValid()) {
      setValue(time + '\n' + value);
    }
  };

  return (
    <div className={classes.page}>
      <div className={classes.container}>
        <Layout>
          <div className={classes.twoColumns}>
            <div className={classes.timestamp} onClick={insertTimestamp}>
              Timestamp
            </div>
            <IconButton
              onMouseDown={startRecord}
              onTouchStart={startRecord}
              onMouseUp={stopRecord}
              onTouchEnd={stopRecord}
              color={active ? 'error' : 'primary'}
              sx={{p: 0}}
            >
              <img src={speechInput.micIcon} />
            </IconButton>
          </div>
          <Divider />
          <TextField
            fullWidth
            multiline
            rows={4}
            value={value}
            onChange={handleChange}
            InputProps={{sx: {fontSize: '25px'}}}
          />
        </Layout>
      </div>
    </div>
  );
};
