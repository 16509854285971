import React, {FC, useContext} from 'react';
import {map} from 'lodash';

import Button from 'components/Button';
import CardItem from 'components/CardItem';
import DeviceCard from 'components/DeviceCard';
import Layout from 'components/Layout';
import Link from 'components/Link';
import MenuBar from 'components/MenuBar';
import BrandContext from 'contexts/BrandContext';

import {ROUTES} from 'routes';

import useStyles from './styles';

export const SelectProblem: FC = () => {
  const {selectProblemPage} = useContext(BrandContext);
  const classes = useStyles();
  return (
    <>
      <MenuBar
        wide
        showDate
        icon={selectProblemPage.headerIcon}
        title="Work Order: WO-00217"
        subTitle="Inspection"
      />
      <Layout>
        <DeviceCard {...selectProblemPage.deviceCard}>
          <Button
            fullWidth
            outline
            title="CHANGE"
            navigateTo={ROUTES.DEVICE_LIST}
          />
        </DeviceCard>
        <div className={classes.title}>Select a problem</div>
        {map(selectProblemPage.problems, ({link, ...otherProps}) => (
          <Link
            key={otherProps.title?.toString()}
            to={link + '?reason=' + otherProps.title}
          >
            <CardItem {...otherProps} minHeight={null} />
          </Link>
        ))}
      </Layout>
    </>
  );
};
