import {makeStyles} from '@mui/styles';

import * as COLORS from 'colors';

const useStyles = makeStyles(() => ({
  divider: {
    background: ({color}: any) => color,
    height: '5px',
    width: '100%',
    borderRadius: '4px',
  },
}));

export default useStyles;
