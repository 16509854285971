import React, {FC} from 'react';
import cx from 'classnames';
import {useHistory} from 'react-router-dom';

import {default as MuiButton} from '@mui/material/Button';

import useStyles from './styles';

interface ButtonProps {
  className?: string;
  onClick?: () => void;
  title?: string;
  navigateTo?: string;
  outline?: boolean;
  fullWidth?: boolean;
  sx?: any;
}

export const Button: FC<ButtonProps> = ({
  className,
  onClick,
  title,
  navigateTo,
  outline,
  fullWidth,
  sx,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const clickHandler = (e) => {
    e.stopPropagation();
    onClick?.();
    if (navigateTo) {
      history.push(navigateTo);
    }
  };
  return (
    <div style={{textAlign: 'center'}}>
      <MuiButton
        className={cx(className, classes.button)}
        onClick={clickHandler}
        variant={outline ? 'outlined' : 'contained'}
        fullWidth={fullWidth}
        sx={sx}
      >
        {title}
      </MuiButton>
    </div>
  );
};
