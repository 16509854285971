import {makeStyles} from '@mui/styles';

import * as COLORS from 'colors';

const useStyles = makeStyles(() => ({
  menuBar: {
    position: 'sticky',
    top: 0,
    height: ({wide}: any) => (wide ? '136px' : '69px'),
    backgroundColor: ({background}) => background,
    color: COLORS.WHITE,
    padding: '10px 24px 10px',
    zIndex: 1000,
  },
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
  titleContainer: ({center}) =>
    center
      ? {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: -1,
        }
      : {},
  icon: {
    marginRight: '40px',
    height: '72px',
  },
  title: {
    fontWeight: 700,
    fontSize: '25px',
  },
  subTitle: {
    fontSize: '25px',
  },
  timestamp: {
    position: 'absolute',
    bottom: '32px',
    right: '24px',
  },
}));

export default useStyles;
