import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from '@mui/material/styles';
import {BrowserRouter as Router} from 'react-router-dom';

import {LoginContextProvider} from 'contexts/LoginContext';
import BrandContext, {BrandContextProvider} from 'contexts/BrandContext';
import {IBrand} from 'contexts/types';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

window.oncontextmenu = () => false;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <BrandContextProvider>
        <LoginContextProvider>
          <BrandContext.Consumer>
            {({theme}: IBrand) => (
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
            )}
          </BrandContext.Consumer>
        </LoginContextProvider>
      </BrandContextProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
