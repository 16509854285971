import React, {FC, useContext, useMemo} from 'react';
import cx from 'classnames';
import moment from 'moment';
import {compact} from 'lodash';

import BrandContext from 'contexts/BrandContext';

import useStyles from './styles';

interface TimestampProps {
  className?: string;
  date?: boolean;
  time?: boolean;
}

export function getTimeString({
  date,
  time,
}: {date?: boolean; time?: boolean} = {}): string {
  return moment()
    .utcOffset(2)
    .format(compact([date && 'll', time && 'LT']).join(' '));
}

export const Timestamp: FC<TimestampProps> = ({className, date, time}) => {
  const {timestamp} = useContext(BrandContext);
  const classes = useStyles(timestamp);

  const value = useMemo(() => getTimeString({date, time}), [date, time]);
  return <div className={cx(className, classes.timestamp)}>{value}</div>;
};
