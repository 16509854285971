import React from 'react';
import {createTheme} from '@mui/material/styles';

import logo from 'assets/images/airline/logo.png';
import assignment from 'assets/icons/airline/assignment.svg';
import noteAlt from 'assets/icons/airline/note-alt.svg';
import noteAltW from 'assets/icons/airline/note-alt-w.svg';
import description from 'assets/icons/airline/description.svg';
import photoCamera from 'assets/icons/airline/photo-camera.svg';
import mic from 'assets/icons/airline/mic.svg';
import borderColor from 'assets/icons/airline/border-color.svg';
import task from 'assets/icons/airline/task.svg';
import brokenScreen from 'assets/images/airline/broken-screen.png';
import photoButton from 'assets/images/airline/photo-button.svg';
import hmx715 from 'assets/images/airline/devices/hmx715.png';
import hm500 from 'assets/images/airline/devices/hm500.png';
import hme715 from 'assets/images/airline/devices/hme715.png';
import hms700 from 'assets/images/airline/devices/hms700.png';
import memory from 'assets/icons/airline/memory.svg';
import book from 'assets/icons/airline/book.svg';
import login from 'assets/icons/airline/login.svg';

import * as COLORS from 'colors';
import {ROUTES} from 'routes';

import {IBrand} from '../types';

const airline: IBrand = {
  theme: createTheme({
    palette: {
      primary: {
        main: COLORS.PRUSSIAN_BLUE,
      },
      background: {
        default: COLORS.WHITE,
      },
      text: {
        primary: COLORS.PRUSSIAN_BLUE,
      },
    },
    typography: {
      fontFamily: '"Roboto", "Arial"',
    },
    components: {
      MuiButton: {
        styleOverrides: {
          outlinedPrimary: {
            borderColor: COLORS.PRUSSIAN_BLUE,
            borderWidth: '2px',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: COLORS.PRUSSIAN_BLUE,
            borderWidth: '2px',
          },
          input: {
            fontSize: '25px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: COLORS.PRUSSIAN_BLUE,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: `${COLORS.PRUSSIAN_BLUE} !important`,
            fontSize: '20px',
          },
        },
      },
    },
  }),
  header: {
    background: COLORS.PRUSSIAN_BLUE,
    eyebrow: null,
  },
  homePage: {
    logo,
    cards: [
      {
        title: 'My work orders',
        subTitle: '1 active work order',
        link: ROUTES.WORK_ORDER,
        icon: assignment,
      },
      {
        title: 'Create new work order',
        link: ROUTES.HOME,
        icon: noteAlt,
      },
    ],
  },
  workOrderPage: {
    headerIcon: task,
    cards: [
      {
        title: 'Description',
        subTitle:
          'A post-flight inspection of the aircraft cabin showed that touch panel 14F is faulty. Please inspect.',
        link: ROUTES.TAKE_PHOTO,
        icon: description,
      },
      {
        title: 'Take picture',
        link: ROUTES.TAKE_PHOTO,
        icon: photoCamera,
      },
      {
        title: 'Voice recording',
        link: ROUTES.TAKE_PHOTO,
        icon: mic,
      },
      {
        title: 'Create note',
        link: ROUTES.TAKE_PHOTO,
        icon: borderColor,
      },
    ],
  },
  takePicturePage: {
    background: COLORS.WHITE,
    cameraPicture: brokenScreen,
    photoButton,
  },
  selectProblemPage: {
    headerIcon: task,
    deviceCard: {
      icon: hmx715,
      title: 'Panasonic HMx715',
      extraInfo: '84%',
    },
    problems: [
      {
        title: 'Dead or stuck pixels',
        link: ROUTES.WORK_ORDER_SPEECH,
        score: 96,
        highlight: true,
      },
      {
        title: 'Touch screen is not responding',
        link: ROUTES.WORK_ORDER_SPEECH,
        score: 58,
      },
      {
        title: 'Display is black',
        link: ROUTES.WORK_ORDER_SPEECH,
        score: 29,
      },
      {
        title: 'Screen is broken',
        link: ROUTES.WORK_ORDER_SPEECH,
        score: 1,
      },
    ],
  },
  deviceListPage: {
    devices: [
      {
        title: 'Panasonic HM515',
        icon: hm500,
        extraInfo: '72%',
      },
      {
        title: 'Panasonic HMe715',
        icon: hme715,
        extraInfo: '68%',
      },
      {
        title: 'Panasonic HMs715',
        icon: hms700,
        extraInfo: '62%',
      },
      {
        title: 'Panasonic HM510',
        icon: hm500,
        extraInfo: '57%',
      },
      {
        title: 'Panasonic HMe710',
        icon: hme715,
        extraInfo: '44%',
      },
      {
        title: 'Panasonic HMs710',
        icon: hms700,
        extraInfo: '40%',
      },
      {
        title: 'Panasonic HMe705',
        icon: hm500,
        extraInfo: '39%',
      },
    ],
  },
  workOrderSpeechPage: {
    headerIcon: task,
    description:
      'A post-flight inspection of the aircraft cabin showed that touch panel 14F is faulty. Please inspect.',
    descriptionIcon: description,
    deviceIcon: hmx715,
    deviceName: 'Panasonic HMx715',
  },
  newWorkOrderPage: {
    headerIcon: noteAltW,
    descriptionIcon: description,
    spareIcon: memory,
    spareDescription: '1 item required',
    deviceIcon: hmx715,
    deviceName: 'Panasonic HMx715',
    maintenanceIcon: book,
  },
  chatPage: {
    chatUrl: 'https://airlinework-chat.demo.terrific.ai',
  },
  loginPage: {
    loginIcon: login,
    signInColor: COLORS.PRUSSIAN_BLUE,
  },
  card: {
    background: COLORS.WHITE,
    backgroundHighlighted: COLORS.PRUSSIAN_BLUE,
    border: `2px solid ${COLORS.PRUSSIAN_BLUE}`,
    borderHighlighted: 'none',
    boxShadow: 'none',
    titleColorHighlighted: COLORS.WHITE,
    extraColor: COLORS.PRUSSIAN_BLUE,
  },
  speechInput: {
    micIcon: mic,
    timestampButtonColor: COLORS.PRUSSIAN_BLUE,
  },
  timestamp: {
    color: COLORS.WHITE,
  },
  divider: {
    color: COLORS.PRUSSIAN_BLUE,
  },
};

export default airline;
