import React from 'react';
import {createTheme} from '@mui/material/styles';

import factory from 'assets/images/factory.jpg';

import * as COLORS from 'colors';
import {ROUTES} from 'routes';

import eyebrow from 'assets/images/eyebrow.svg';
import clipboard from 'assets/icons/clipboard.svg';
import filePlus from 'assets/icons/file-plus.svg';
import camera from 'assets/icons/camera.svg';
import edit from 'assets/icons/edit.svg';
import fileText from 'assets/icons/file-text.svg';
import mic from 'assets/icons/mic.svg';
import file from 'assets/icons/file.svg';
import filePlusW from 'assets/icons/file-plus-w.svg';
import brokenScreen from 'assets/images/broken-screen.png';
import photoButton from 'assets/images/photo-button.svg';
import ktp700f from 'assets/images/devices/ktp700f.png';
import ktp900f from 'assets/images/devices/ktp900f.png';
import ktp400f from 'assets/images/devices/ktp400f.png';
import ktp700 from 'assets/images/devices/ktp700.png';
import ktp900 from 'assets/images/devices/ktp900.png';
import tp1000f from 'assets/images/devices/tp1000f.png';
import ktp700fhw from 'assets/images/devices/ktp700f-hw.png';
import ktp700fhwor from 'assets/images/devices/ktp700f-hw-or.png';
import cpu from 'assets/icons/cpu.svg';
import book from 'assets/icons/book.svg';
import lock from 'assets/icons/lock.svg';

import {IBrand} from '../types';

const siemens: IBrand = {
  theme: createTheme({
    palette: {
      background: {
        default: COLORS.WILD_SAND,
      },
      primary: {
        main: COLORS.EASTERN_BLUE,
      },
    },
  }),
  header: {
    background: COLORS.MINE_SHAFT,
    eyebrow,
  },
  homePage: {
    logo: factory,
    cards: [
      {
        title: 'My work orders',
        subTitle: <div style={{color: '#009999'}}>1 active work order</div>,
        link: ROUTES.WORK_ORDER,
        icon: clipboard,
      },
      {
        title: 'Create new work order',
        link: ROUTES.HOME,
        icon: filePlus,
      },
    ],
  },
  workOrderPage: {
    headerIcon: file,
    cards: [
      {
        title: 'Description',
        subTitle:
          'A conveyor control panel was hit by a moving forklift today. Looks like we have a damage here. Please inspect.',
        link: ROUTES.TAKE_PHOTO,
        icon: fileText,
      },
      {
        title: 'Take picture',
        link: ROUTES.TAKE_PHOTO,
        icon: camera,
      },
      {
        title: 'Voice recording',
        link: ROUTES.TAKE_PHOTO,
        icon: mic,
      },
      {
        title: 'Create note',
        link: ROUTES.TAKE_PHOTO,
        icon: edit,
      },
    ],
  },
  takePicturePage: {
    background: COLORS.BLACK,
    cameraPicture: brokenScreen,
    photoButton,
  },
  selectProblemPage: {
    headerIcon: file,
    deviceCard: {
      icon: ktp700f,
      title: 'SIMATIC HMI KTP700F Mobile',
      extraInfo: '84%',
    },
    problems: [
      {
        title: 'Screen is broken',
        link: ROUTES.WORK_ORDER_SPEECH,
        score: 96,
        highlight: true,
      },
      {
        title: 'Touch screen is not responding',
        link: ROUTES.WORK_ORDER_SPEECH,
        score: 58,
      },
      {
        title: 'Display is black',
        link: ROUTES.WORK_ORDER_SPEECH,
        score: 49,
      },
      {
        title: 'Dead pixels',
        link: ROUTES.WORK_ORDER_SPEECH,
        score: 1,
      },
    ],
  },
  deviceListPage: {
    devices: [
      {
        title: 'SIMATIC HMI KTP900F Mobile',
        icon: ktp900f,
        extraInfo: '72%',
      },
      {
        title: 'SIMATIC HMI KTP400F Mobile',
        icon: ktp400f,
        extraInfo: '68%',
      },
      {
        title: 'SIMATIC HMI KTP700 Mobile',
        icon: ktp700,
        extraInfo: '62%',
      },
      {
        title: 'SIMATIC HMI KTP900 Mobile',
        icon: ktp900,
        extraInfo: '57%',
      },
      {
        title: 'SIMATIC HMI TP1000F MOBILE',
        icon: tp1000f,
        extraInfo: '44%',
      },
      {
        title: 'SIMATIC HMI KTP700F Mobile HW',
        icon: ktp700fhw,
        extraInfo: '40%',
      },
      {
        title: 'SIMATIC HMI KTP700F Mobile HW/OR',
        icon: ktp700fhwor,
        extraInfo: '39%',
      },
    ],
  },
  workOrderSpeechPage: {
    headerIcon: file,
    description:
      'A conveyor control panel was hit by a moving forklift today. Looks like we have a damage here. Please inspect.',
    descriptionIcon: fileText,
    deviceIcon: ktp700f,
    deviceName: 'SIMATIC HMI KTP700F Mobile',
  },
  newWorkOrderPage: {
    headerIcon: filePlusW,
    descriptionIcon: fileText,
    spareIcon: cpu,
    spareDescription: <div style={{color: '#009999'}}>1 item required</div>,
    deviceIcon: ktp700f,
    deviceName: 'SIMATIC HMI KTP700F Mobile',
    maintenanceIcon: book,
  },
  chatPage: {
    chatUrl: 'https://workorders-chat.demo.terrific.ai/',
  },
  loginPage: {
    loginIcon: lock,
    signInColor: COLORS.BONDI_BLUE,
  },
  card: {
    background: COLORS.WHITE,
    backgroundHighlighted: COLORS.WHITE,
    border: `2px solid ${COLORS.SILVER}`,
    borderHighlighted: `3px solid ${COLORS.EASTERN_BLUE}`,
    boxShadow: '0px 10px 20px -8px rgba(0, 0, 0, 0.25)',
    extraColor: COLORS.EASTERN_BLUE,
  },
  divider: {
    color: COLORS.EASTERN_BLUE,
  },
  speechInput: {
    micIcon: mic,
    timestampButtonColor: COLORS.DUSTY_GRAY,
  },
  timestamp: {
    color: COLORS.DUSTY_GRAY,
  },
};

export default siemens;
