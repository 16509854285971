import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
  layout: {
    padding: '24px',
    '& >*:not(:last-child)': {
      marginBottom: '24px',
    },
  },
}));

export default useStyles;
