import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';

interface LinkProps {
  to?: string;
}

export const Link: FC<LinkProps> = ({to, children}) => {
  const history = useHistory();
  return <div onClick={() => to && history.push(to)}>{children}</div>;
};
