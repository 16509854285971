import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    fontSize: '25px',
    padding: '6px 15px',
  },
}));

export default useStyles;
