import React, {FC, useContext} from 'react';

import BrandContext from 'contexts/BrandContext';

import useStyles from './styles';

export interface CardItemProps {
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  description?: string | React.ReactNode;
  icon?: string;
  minHeight?: number | null;
  score?: number;
  highlight?: boolean;
}

export const CardItem: FC<CardItemProps> = ({
  minHeight = 151,
  title,
  subTitle,
  description,
  icon,
  score,
  highlight,
  children,
}) => {
  const {card} = useContext(BrandContext);
  const classes = useStyles({highlight, minHeight, ...card});
  return (
    <div className={classes.card}>
      <div className={classes.container}>
        {icon && <img src={icon} />}
        <div>
          {title && <div className={classes.cardTitle}>{title}</div>}
          {subTitle && <div className={classes.cardSubtitle}>{subTitle}</div>}
          {description && (
            <div className={classes.cardDescription}>{description}</div>
          )}
        </div>
        {score && <div className={classes.score}>{score}%</div>}
      </div>
      {children}
    </div>
  );
};
