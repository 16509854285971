import {makeStyles} from '@mui/styles';

import * as COLORS from 'colors';

const useStyles = makeStyles(() => ({
  page: {
    textAlign: 'center',
  },
  container: {
    border: ({border}: any) => border,
    borderRadius: '6px',
    boxShadow: '0px 10px 20px -8px rgba(0, 0, 0, 0.25)',
    background: COLORS.WHITE,
  },
  twoColumns: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  timestamp: {
    fontSize: '20px',
    color: ({timestampButtonColor}) => timestampButtonColor,
    cursor: 'pointer',
  },
}));

export default useStyles;
