import React, {FC, useContext} from 'react';
import CardItem from 'components/CardItem';
import {map} from 'lodash';

import Link from 'components/Link';
import MenuBar from 'components/MenuBar';
import Layout from 'components/Layout';
import BrandContext from 'contexts/BrandContext';

import useStyles from './styles';

export const HomePage: FC = () => {
  const {homePage} = useContext(BrandContext);
  const classes = useStyles();
  return (
    <>
      <MenuBar />
      <img className={classes.headerImage} src={homePage.logo} />
      <Layout>
        {map(homePage.cards, ({link, ...otherProps}) => (
          <Link key={link} to={link}>
            <CardItem {...otherProps} />
          </Link>
        ))}
      </Layout>
    </>
  );
};
