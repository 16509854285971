import React, {FC, useContext} from 'react';
import CardItem from 'components/CardItem';
import {ROUTES} from 'routes';
import {useLocation} from 'react-router-dom';

import Link from 'components/Link';
import MenuBar from 'components/MenuBar';
import Layout from 'components/Layout';
import Button from 'components/Button';
import Delimiter from 'components/Divider';
import DeviceCard from 'components/DeviceCard';
import BrandContext from 'contexts/BrandContext';

import useStyles from './styles';

export const NewWorkOrderPage: FC = () => {
  const {search} = useLocation();
  const params = new URLSearchParams(search);
  const reason = params.get('reason') ?? 'Replace the broken equipment';
  const {newWorkOrderPage} = useContext(BrandContext);

  const classes = useStyles();
  return (
    <>
      <MenuBar
        wide
        icon={newWorkOrderPage.headerIcon}
        title="Create new Work order"
        subTitle="Repair"
      />
      <Layout>
        <CardItem
          title="Description"
          subTitle={reason}
          icon={newWorkOrderPage.descriptionIcon}
        />
        <CardItem
          title="Spare parts"
          subTitle={newWorkOrderPage.spareDescription}
          icon={newWorkOrderPage.spareIcon}
        >
          <Delimiter />
          <div style={{height: '24px'}} />
          <DeviceCard
            noCard
            title={newWorkOrderPage.deviceName}
            icon={newWorkOrderPage.deviceIcon}
            smallExtra
            subTitle="Item in stock"
          />
        </CardItem>
        <Link to={ROUTES.CHAT}>
          <CardItem
            title="Maintenance manuals"
            subTitle="Troubleshooting assistant"
            icon={newWorkOrderPage.maintenanceIcon}
          />
        </Link>
        <Button
          fullWidth
          className={classes.button}
          navigateTo={ROUTES.HOME}
          title="Schedule"
        />
      </Layout>
    </>
  );
};
