import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
  button: {
    padding: '45px 0',
    fontWeight: 700,
    fontSize: '35px',
  },
}));

export default useStyles;
