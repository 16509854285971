import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
  timestamp: {
    fontSize: '20px',
    color: ({color}: any) => color,
  },
}));

export default useStyles;
