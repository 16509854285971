import React, {FC, useContext} from 'react';
import CardItem from 'components/CardItem';
import {map} from 'lodash';

import Layout from 'components/Layout';
import Link from 'components/Link';
import MenuBar from 'components/MenuBar';
import BrandContext from 'contexts/BrandContext';

export const WorkOrderPage: FC = () => {
  const {workOrderPage} = useContext(BrandContext);
  return (
    <>
      <MenuBar
        wide
        showDate
        icon={workOrderPage.headerIcon}
        title="Work Order: WO-00217"
        subTitle="Inspection"
      />
      <Layout>
        {map(workOrderPage.cards, ({link, ...otherProps}) => (
          <Link key={otherProps.title?.toString()} to={link}>
            <CardItem {...otherProps} />
          </Link>
        ))}
      </Layout>
    </>
  );
};
