import React, {FC, useContext, useState} from 'react';
import CardItem from 'components/CardItem';
import {useLocation} from 'react-router-dom';
import {ROUTES} from 'routes';

import MenuBar from 'components/MenuBar';
import Layout from 'components/Layout';
import SpeechInput from 'components/SpeechInput';
import DeviceCard from 'components/DeviceCard';
import Button from 'components/Button';
import BrandContext from 'contexts/BrandContext';

import fileText from 'assets/icons/file-text.svg';
import ktp700f from 'assets/images/devices/ktp700f.png';

import useStyles from './styles';

export const WorkOrderSpeechPage: FC = () => {
  const {search} = useLocation();
  const params = new URLSearchParams(search);
  const [reason, setReason] = useState(
    params.get('reason') ?? 'Screen is broken.',
  );
  const {workOrderSpeechPage} = useContext(BrandContext);
  const classes = useStyles();
  return (
    <>
      <MenuBar
        wide
        showDate
        icon={workOrderSpeechPage.headerIcon}
        title="Work Order: WO-00217"
        subTitle="Inspection"
      />
      <Layout>
        <CardItem
          icon={workOrderSpeechPage.descriptionIcon}
          title="Description"
          subTitle={workOrderSpeechPage.description}
        />
        <DeviceCard
          icon={workOrderSpeechPage.deviceIcon}
          title={workOrderSpeechPage.deviceName}
          extraInfo="84%"
        >
          <Button
            fullWidth
            outline
            title="CHANGE"
            navigateTo={ROUTES.DEVICE_LIST}
          />
        </DeviceCard>
        <SpeechInput defaultValue={reason} onChange={setReason} />
        <Button
          fullWidth
          className={classes.button}
          navigateTo={ROUTES.FINAL_FORM + '?reason=' + reason}
          title="INSPECTION COMPLETE"
        />
      </Layout>
    </>
  );
};
