import React, {FC, createContext, useState} from 'react';

interface IContextValue {
  loggedIn: boolean;
  setLoggedIn: (loggedIn: boolean) => void;
}

const LoginContext = createContext<IContextValue>({
  loggedIn: false,
  setLoggedIn: () => undefined,
});

export const LoginContextProvider: FC = ({children}) => {
  const [loggedIn, setLoggedIn] = useState(false);
  return (
    <LoginContext.Provider value={{loggedIn, setLoggedIn}}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
