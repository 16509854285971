import React, {FC, useContext} from 'react';
import {map} from 'lodash';
import {useHistory} from 'react-router-dom';

import DeviceCard from 'components/DeviceCard';
import Layout from 'components/Layout';
import Link from 'components/Link';
import MenuBar from 'components/MenuBar';
import BrandContext from 'contexts/BrandContext';

import {ROUTES} from 'routes';

import useStyles from './styles';

export const DeviceListPage: FC = () => {
  const history = useHistory();
  const {deviceListPage} = useContext(BrandContext);
  const classes = useStyles();
  return (
    <>
      <MenuBar center wide title="Change equipment" />
      <Layout>
        {map(deviceListPage.devices, (deviceProps) => (
          <Link key={deviceProps.title} to={ROUTES.SELECT_PROBLEM}>
            <DeviceCard {...deviceProps} onClick={() => history.goBack()} />
          </Link>
        ))}
      </Layout>
    </>
  );
};
