import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '35px',
    fontWeight: 700,
    marginTop: '68px',
  },
}));

export default useStyles;
