import {makeStyles} from '@mui/styles';

import * as COLORS from 'colors';

const useStyles = makeStyles(() => ({
  card: {
    boxShadow: ({boxShadow}) => boxShadow,
    borderRadius: '6px',
    border: ({highlight, border, borderHighlighted}) =>
      highlight ? borderHighlighted : border,
    padding: '32px',
    minHeight: ({minHeight}: any) => `${minHeight}px`,
    background: ({background, backgroundHighlighted, highlight}) =>
      highlight ? backgroundHighlighted : background,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    color: ({highlight, titleColorHighlighted}) =>
      (highlight && titleColorHighlighted) || undefined,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: '36px',
    alignItems: 'center',
    '&:not(:last-child)': {marginBottom: '24px'},
  },
  cardTitle: {
    position: 'relative',
    fontSize: '25px',
    fontWeight: 700,
  },
  arrowIcon: {
    position: 'absolute',
    right: 0,
    top: '28px',
  },
  cardSubtitle: {
    fontSize: '25px',
    // color: COLORS.DUSTY_GRAY,
  },
  cardDescription: {
    fontSize: '25px',
  },
  score: {
    fontSize: '28px',
    color: ({highlight, titleColorHighlighted, extraColor}) =>
      (highlight && titleColorHighlighted) || extraColor,
    position: 'absolute',
    right: '24px',
  },
}));

export default useStyles;
