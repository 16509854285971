import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    maxWidth: '100%',
    height: '100vh',
    background: ({background}: any) => background,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    objectFit: 'scale-down',
  },
  image: {},
}));

export default useStyles;
