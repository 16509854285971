import React, {FC, useContext} from 'react';
import {useHistory} from 'react-router-dom';

import MenuBar from 'components/MenuBar';
import BrandContext from 'contexts/BrandContext';
import {ROUTES} from 'routes';

import useStyles from './styles';

export const TakePicturePage: FC = () => {
  const history = useHistory();
  const {takePicturePage} = useContext(BrandContext);
  const classes = useStyles(takePicturePage);
  return (
    <div
      className={classes.container}
      onClick={() => history.push(ROUTES.SELECT_PROBLEM)}
    >
      <MenuBar center wide title="Take picture" />
      <img className={classes.image} src={takePicturePage.cameraPicture} />
      <div style={{textAlign: 'center', marginBottom: '40px'}}>
        <img src={takePicturePage.photoButton} />
      </div>
    </div>
  );
};
