import React, {FC, createContext, useMemo} from 'react';

import {IBrand} from './types';
import {getBrand} from './brands';

const BrandContext = createContext<IBrand>({} as IBrand);

export const BrandContextProvider: FC = ({children}) => {
  const brandData = useMemo(getBrand, []);
  return (
    <BrandContext.Provider value={brandData}>{children}</BrandContext.Provider>
  );
};

export default BrandContext;
