import React, {useContext} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import LoginContext from 'contexts/LoginContext';
import BrandContext from 'contexts/BrandContext';

import * as COLORS from 'colors';

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://www.datamonsters.com/">
        www.datamonsters.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function LoginPage() {
  const {loginPage} = useContext(BrandContext);
  const {setLoggedIn} = useContext(LoginContext);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get('password') === 'monster') {
      setLoggedIn(true);
    }
  };

  return (
    <Container component="main" style={{padding: '0 24px'}}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{mt: 1}}
          alignItems="center"
          display="flex"
          flexDirection="column"
          width="100%"
        >
          <img src={loginPage.loginIcon} />
          <div
            style={{
              fontSize: '28px',
              fontWeight: 'bold',
              marginTop: '10px',
              marginBottom: '50px',
            }}
          >
            Sign in
          </div>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value="demo@datamonsters.com"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <div style={{textAlign: 'center'}}>
            <Button
              type="submit"
              variant="contained"
              sx={{
                marginTop: '72px',
                marginBottom: '60px',
                padding: '17px 78px',
                background: loginPage.signInColor,
                fontWeight: 700,
                fontSize: '25px',
              }}
              color="primary"
            >
              Sign In
            </Button>
          </div>
          <Copyright sx={{mt: 8, mb: 4}} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <div>
            <Link href="#" variant="body2" style={{fontSize: '25px'}}>
              Forgot password?
            </Link>
          </div>
          <div style={{height: '24px'}} />
          <div>
            <Link href="#" variant="body2" style={{fontSize: '25px'}}>
              Dont have an account? Sign Up
            </Link>
          </div>
        </Box>
      </Box>
    </Container>
  );
}
