import React, {FC, useContext} from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import {useHistory, useLocation} from 'react-router-dom';

import {ROUTES} from 'routes';
import Timestamp from 'components/Timestamp';
import BrandContext from 'contexts/BrandContext';

import NavigationLabel from './NavigationLabel';

import useStyles from './styles';

interface MenuBarProps {
  title?: string;
  subTitle?: string;
  icon?: string;
  showDate?: boolean;
  wide?: boolean;
  center?: boolean;
}

export const MenuBar: FC<MenuBarProps> = ({
  title,
  subTitle,
  icon,
  showDate,
  wide,
  center,
}) => {
  const {header} = useContext(BrandContext);
  const classes = useStyles({center, wide, ...header});
  const history = useHistory();
  const location = useLocation();
  const isHome = location.pathname === ROUTES.HOME;
  return (
    <div
      className={classes.menuBar}
      style={{
        width: '100%',
      }}
    >
      <NavigationLabel />
      <div className={classes.container}>
        {!isHome && (
          <IconButton
            color="inherit"
            edge="start"
            onClick={() => history.goBack()}
          >
            <ArrowBackIos />
          </IconButton>
        )}
        {icon && <img className={classes.icon} src={icon} />}
        {(title || subTitle) && (
          <div className={classes.titleContainer}>
            {title && <div className={classes.title}>{title}</div>}
            {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
          </div>
        )}
        {showDate && <Timestamp date className={classes.timestamp} />}
      </div>
    </div>
  );
};
