import React, {FC, useContext} from 'react';
import {Route, Switch} from 'react-router-dom';

import {ROUTES} from 'routes';
import HomePage from 'components/pages/HomePage';
import WorkOrderPage from 'components/pages/WorkOrderPage';
import SelectProblemPage from 'components/pages/SelectProblemPage';
import DeviceListPage from 'components/pages/DeviceListPage';
import WorkOrderSpeechPage from 'components/pages/WorkOrderSpeechPage';
import TakePicturePage from 'components/pages/TakePicturePage';
import NewWorkOrderPage from 'components/pages/NewWorkOrderPage';
import ChatPage from 'components/pages/ChatPage';
import LoginPage from 'components/pages/LoginPage';

import LoginContext from 'contexts/LoginContext';

import useStyles from './styles';

export const Content: FC = () => {
  const loginContext = useContext(LoginContext);
  const classes = useStyles();
  return (
    <div className={classes.content}>
      {loginContext.loggedIn ? (
        <Switch>
          <Route path={ROUTES.WORK_ORDER} component={WorkOrderPage} />
          <Route path={ROUTES.TAKE_PHOTO} component={TakePicturePage} />
          <Route path={ROUTES.SELECT_PROBLEM} component={SelectProblemPage} />
          <Route path={ROUTES.DEVICE_LIST} component={DeviceListPage} />
          <Route
            path={ROUTES.WORK_ORDER_SPEECH}
            component={WorkOrderSpeechPage}
          />
          <Route path={ROUTES.FINAL_FORM} component={NewWorkOrderPage} />
          <Route path={ROUTES.CHAT} component={ChatPage} />
          <Route component={HomePage} />
        </Switch>
      ) : (
        <LoginPage />
      )}
    </div>
  );
};
