import React, {FC, useContext} from 'react';
import {useHistory} from 'react-router-dom';

import BrandContext from 'contexts/BrandContext';

import useStyles from './styles';

export interface DeviceCardProps {
  onClick?: () => void;
  navigateTo?: string;
  icon?: string;
  title?: string;
  subTitle?: string;
  extraInfo?: string;
  noCard?: boolean;
  smallExtra?: boolean;
}

export const DeviceCard: FC<DeviceCardProps> = ({
  children,
  onClick,
  navigateTo,
  icon,
  title,
  subTitle,
  extraInfo,
  noCard,
  smallExtra,
}) => {
  const history = useHistory();
  const {card} = useContext(BrandContext);
  const classes = useStyles({smallExtra, ...card});
  const clickHandler = (e) => {
    e.stopPropagation();
    onClick?.();
    if (navigateTo) {
      history.push(navigateTo);
    }
  };
  const wrap = noCard
    ? (wrapped) => <>{wrapped}</>
    : (wrapped) => (
        <div className={classes.card} onClick={clickHandler}>
          {wrapped}
        </div>
      );
  return wrap(
    <>
      <div className={classes.container}>
        {icon && <img className={classes.image} src={icon} />}
        {(title || subTitle) && (
          <div className={classes.titles}>
            {title && <div className={classes.title}>{title}</div>}
            {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
          </div>
        )}
        {extraInfo && <div className={classes.extraInfo}>{extraInfo}</div>}
      </div>
      {children}
    </>,
  );
};
