import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';

import Content from 'components/Content';

function App() {
  return (
    <Box sx={{display: 'flex'}}>
      <CssBaseline />
      <Box component="main" sx={{flexGrow: 1, p: 0}}>
        <Content />
      </Box>
    </Box>
  );
}

export default App;
