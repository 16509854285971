// named with https://chir.ag/projects/name-that-color/

export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const WILD_SAND = '#F6F6F6';
export const MINE_SHAFT = '#323232';
export const FOUNTAIN_BLUE = '#4EBEBF';
export const BONDI_BLUE = '#0A91BA';
export const SILVER = '#CBCBCB';
export const DUSTY_GRAY = '#9A9A9A';
export const EASTERN_BLUE = '#2486A9';
export const PRUSSIAN_BLUE = '#00205B';
