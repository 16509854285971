export enum ROUTES {
  HOME = '/',
  WORK_ORDER = '/work-order',
  TAKE_PHOTO = '/take-photo',
  SELECT_PROBLEM = '/select-problem',
  DEVICE_LIST = '/device-list',
  WORK_ORDER_SPEECH = '/work-order-speech',
  FINAL_FORM = '/final-form',

  CHAT = '/chat',
  TECHNICAL_STATUS = '/technical-status',
  MAINTENANCE_MANUAL = '/maintenance-manual',
  CONFIRM_APPLICATION = '/confirm-application',
  SPEECH = '/speech',
}
