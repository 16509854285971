import {makeStyles} from '@mui/styles';

import * as COLORS from 'colors';

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: COLORS.WHITE,
    padding: '24px',
    border: ({border}) => border,
    borderRadius: '6px',
    boxShadow: ({boxShadow}) => boxShadow,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '24px',
    justifyContent: 'space-between',
    '&:not(:last-child)': {marginBottom: '24px'},
  },
  image: {
    maxWidth: '84px',
  },
  titles: {
    flex: 1,
  },
  title: {
    fontSize: '25px',
    fontWeight: 700,
  },
  subTitle: {
    fontSize: '25px',
  },
  extraInfo: {
    fontSize: ({smallExtra}: any) => (smallExtra ? '25px' : '28px'),
    color: ({extraColor}) => extraColor,
    whiteSpace: 'nowrap',
  },
}));

export default useStyles;
