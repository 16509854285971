import React, {FC, useContext} from 'react';

import BrandContext from 'contexts/BrandContext';

import useStyles from './styles';

export const Divider: FC = () => {
  const {divider} = useContext(BrandContext);
  const classes = useStyles(divider);
  return <div className={classes.divider} />;
};
