import {IBrand} from 'contexts/types';

import siemens from './siemens';
import airline from './airline';

export const getBrand = (): IBrand => {
  const brand = process.env.REACT_APP_BRAND;
  if (brand === 'airline') {
    return airline;
  }
  return siemens;
};
