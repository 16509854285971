import React, {FC, useContext} from 'react';

import BrandContext from 'contexts/BrandContext';

import useStyles from './styles';

export const NavigationLabel: FC = () => {
  const {header} = useContext(BrandContext);
  const classes = useStyles();
  if (!header.eyebrow) {
    return null;
  }
  return (
    <div>
      <img className={classes.topLine} src={header.eyebrow} />
      {/* <div className={classes.label}>maintenance</div> */}
    </div>
  );
};
