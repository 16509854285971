import {makeStyles} from '@mui/styles';

import * as COLORS from 'colors';

const useStyles = makeStyles(() => ({
  topLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  label: {
    position: 'absolute',
    top: 0,
    left: '72%',
    color: COLORS.MINE_SHAFT,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 700,
    padding: '5px',
  },
}));

export default useStyles;
