import React, {FC, useContext} from 'react';

import MenuBar from 'components/MenuBar';
import BrandContext from 'contexts/BrandContext';

export const ChatPage: FC = () => {
  const {chatPage} = useContext(BrandContext);
  return (
    <div style={{height: '100vh', overflow: 'hidden'}}>
      <MenuBar center title="Troubleshooting" />
      <iframe
        width="100%"
        height="100%"
        src={chatPage.chatUrl}
        allow="microphone *; gamepad *; publickey-credentials-get *; speaker-selection *; sync-xhr *; unoptimized-images *; unsized-media *"
        sandbox="allow-forms allow-popups allow-pointer-lock allow-same-origin allow-scripts allow-top-navigation"
        style={{
          border: 'none',
          height: '100%',
        }}
      />
    </div>
  );
};
